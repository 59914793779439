*, *:before, *:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
*:focus{outline:0;}
::-moz-focus-inner{padding:0;border:0}
html, body{width:100%;min-height:100%;}
body,
button,
input,
select,
textarea{font-family:Lato, Helvetica, Arial, sans-serif;color:#545454;margin:0;font-size:16px;line-height:26px;font-weight:300;}
body{background:#FFF;}
.clearfix{clear:both;}
a{text-decoration:none;color:#c50e1f;-webkit-transition:all 0.2s ease-out;-moz-transition:all 0.2s ease-out;-ms-transition:all 0.2s ease-out;-o-transition:all 0.2s ease-out;transition:all 0.2s ease-out;}
a:hover, a:focus, a:active{color:#c50e1f;text-decoration:underline;}
a:focus{outline:none;}
button{-webkit-transition:all 0.2s ease-out;-moz-transition:all 0.2s ease-out;-ms-transition:all 0.2s ease-out;-o-transition:all 0.2s ease-out;transition:all 0.2s ease-out;}
h1, h2, h3, h4, h5, h6{font-family:Lato, Helvetica, Arial, sans-serif;color:#21252b;font-weight:500;margin-top:0;line-height:100%;}
h1{font-size:2.25em;margin-bottom:10px;}
h2{font-size:44px;margin-bottom:10px;}
h3{font-size:32px;margin-bottom:10px;}
h4{font-size:28px;margin-bottom:10px;}
h5{ font-size:24px;margin-bottom:10px;}
h6{font-size:18px;margin-bottom:10px;}
p{font-family:Lato, Helvetica, Arial, sans-serif;font-size:17px;line-height:26px;font-weight:300;color:#545454;}
i{font-style:normal;}
ul{padding:0 0 0 20px;}
ul li{margin-bottom:5px;}
ul.list3{list-style:none;padding-left:22px;padding:0;}
ul.list3 li{padding:4px 0 4px;font-size:1.0625em;}
ul.list3 li i.fa{color:#c50e1f;font-size:.875em;width:20px;height:20px;line-height:20px;text-align:center;margin-right:4px;}
ul.list3 li{margin-left:22px;}
ul.list3 li i.fa{color:#c50e1f;margin-left:-24px;}
section{position:relative;}
.content{padding:45px 0;background:#FFF;}
.content-2{padding:100px 0;background:#FFF;}
.padd{padding:20px;}
.lead{font-weight:300; font-size: 1.3125em;}
.weight-100 { font-weight: 100; }
.mg-top-40 { margin-top: 40px;}
em{font-size:1.4em;line-height:1.2em;margin-bottom:20px;display:inline-block;}
.line{width:50px;height:1px;background:#c50e1f;margin:0px auto 70px auto;text-align:center;}
.line-left{width:50px;height:1px;margin:10px 0 25px 2px;background:#c50e1f;}
.box-shadow{box-shadow:0px 1px 2px #dcdede;}
.home .layer{position:absolute;top:0;background:transparent url(../img/logo/typeset-logo-bg-verlauf.png) center -100px no-repeat;width:150px;height:200px;left:50%;margin-left:-73px;}
.home .layer img{margin-top:80px;visibility:hidden;}
header.home{padding:280px 0 50px;text-align:center;position:relative;}
header.home h2{color:#FFF;font-weight:400;line-height:1.3em;margin-bottom:25px;text-shadow:1px 2px 10px #333;visibility:hidden;}
header.home p{color:#FFF;font-size:1.5em;font-weight:300;margin-bottom:45px;text-shadow:1px 2px 10px #333;visibility:hidden;}
.section-parallax{background-position:50% 50%;background-repeat:no-repeat;-webkit-background-size:cover;-moz-background-size:cover;-o-background-size:cover;background-size:cover;background-attachment:fixed !important;}
.parallax-content{position:relative;color:#fff;}
.parallax-content p{color:#fff;}
@media only screen and (max-device-width:1024px){.section-parallax{background-position:center top !important;background-attachment:scroll !important;height:auto;margin:0 auto;width:100%;}
}
.single_box{-webkit-transition:all 0.2s ease;-moz-transition:all 0.2s ease;-o-transition:all 0.2s ease;-ms-transition:all 0.2s ease;transition:all 0.2s ease;text-align:center;margin:30px 0 43px;padding:0px 0 20px;}
.single_box:hover{}
.single_box img{border-radius:100%;-webkit-transition:all 0.3s ease-in-out;-moz-transition:all 0.3s ease-in-out;-o-transition:all 0.3s ease-in-out;-ms-transition:all 0.3s ease-in-out;transition:all 0.3s ease-in-out;}
.single_box.style6{padding:0 0 30px;margin:0;}
.single_box.style6 [class^="fa"]{font-size:58px;color:#fff;display:inline-block;width:120px;height:120px;line-height:120px;border-radius:100%;}
.single_box .box{display:inline-block;overflow:hidden;border-radius:100%;}
.single_box .box.default{background:#c50e1f;}
.single_box .box.red{background:#c50e1f;}
.single_box.style6 p.introtext{margin-bottom:0;font-size:1em;}
.single_box a{font-weight:500;margin-top:10px;display:inline-block;}
.single_box a:hover{color:#21252b;text-decoration:none;}
.single_box h3{font-size:1.5em;font-weight:300;margin:10px 0 14px;text-transform:none;}
.single_box p.introtext{margin:10px 0 0;}
.layer{background:#21252b;width:100%;height:100%;position:absolute;top:0;left:0;opacity:0.8;}
#ueberuns h3{color:#FFF;}
.team.style2{text-align:center;}
.team-header{position:relative;}
.team.style2 .team-header img{width:100%;}
.team.style2 .team-date{border:1px solid #f4f6f6;padding:3px 10px 10px 10px;}
.team.style2 .team-date h6{margin:20px 0 0 0;}
.small-text{font-size:.875em;}
.team.style2 .team-date p{margin:10px 0;}
.dark{background:#21252b;}
#footer{color:#b0bac2;padding:15px 0 0 0;}
.footer{background:transparent;padding-bottom:15px;}
#footer h5{color:#fff;}
#footer p{color:#b0bac2;font-size:15px;}
#footer address p{margin-left:23px;}
#footer address i{color:#b0bac2;font-size:14px;margin-left:-23px;}
address i.fa{width:20px;}
footer a, footer a:hover, footer a:active, footer a:focus, footer a:visited{color:#b0bac2;}
#footer > div > div > div:nth-child(3){margin-top:60px;}
#map_canvas{width:100%;height:600px;}
.banner{-webkit-box-shadow:0 1px 2px rgba(43, 48, 51, 0.08);-moz-box-shadow:0 1px 2px rgba(43, 48, 51, 0.08);box-shadow:0 1px 2px rgba(43, 48, 51, 0.08);z-index:20;}
.navbar{margin-bottom:0;}
#logo{float:left;display:block;margin:20px 0;}
#logo .logo-trans{display:none}
.navbar .navbar-nav{float:right;text-align:right;margin:12px 0 0 0;}
.navbar .navbar-nav > li > a{position:relative;color:#000;font-weight:400;padding-top:15px;padding-bottom:15px;-webkit-transition:all 0.1s linear;-moz-transition:all 0.1s linear;transition:all 0.1s linear;}
.navbar .navbar-nav > li > a:after{position:absolute;bottom:7px;left:50%;content:'';width:10px;height:0px;margin-left:-5px;background-color:#fff;-webkit-transition:all 0.1s linear;-moz-transition:all 0.1s linear;transition:all 0.1s linear;}
.navbar .navbar-nav > li > a:hover{color:#828282}
.navbar .navbar-nav > li > a:hover:after, .navbar .navbar-nav > li.active > a:after, .navbar .navbar-nav > li.active > a:hover:after, .navbar .navbar-nav > li.active > a:focus:after{position:absolute;bottom:9px;left:50%;content:'';width:20px;height:1px;margin-left:-10px;background-color:#2b8dd6;}
.navbar-nav > li + li{margin-left:2px}
.navbar .navbar-nav > li.active > a, .navbar .navbar-nav > li.active > a:hover, .navbar .navbar-nav > li.active > a:focus{color:#828282;background-color:transparent;}
.navbar .navbar-nav .dropdown-menu{border-radius:0 !important}
.navbar .navbar-nav .dropdown-menu li a{position:relative}
.navbar .navbar-nav .dropdown-menu li a:after{position:absolute;bottom:2px;left:30px;content:'';width:20px;height:0px;margin-left:-10px;background-color:#fff;-webkit-transition:all 0.1s linear;-moz-transition:all 0.1s linear;transition:all 0.1s linear;}
.navbar .navbar-nav .dropdown-menu li a:hover:after{position:absolute;bottom:3px;left:30px;content:'';width:20px;height:2px;margin-left:-10px;background-color:#e2e2e2;}
.navbar .navbar-nav .dropdown-menu li.active a{background-color:transparent;color:#2b8dd6;}
.navbar .navbar-nav .dropdown-menu li a:hover, .navbar .navbar-nav .dropdown-menu li.active a:hover{color:#2b8dd6;background-color:transparent;}
.navbar-collapse{max-height:none}
.navbar-collapse ul{margin-bottom:10px}
.banner[data-transparent-header="true"]{width:100%;top:0;left:0;position:absolute;overflow:visible !important;z-index:9998;background-color:transparent;box-shadow:none;-webkit-box-shadow:none;-moz-box-shadow:none;-o-box-shadow:none;border-bottom:1px solid rgba(255,255,255,0.25) !important;}
.boxed-mode .banner[data-transparent-header="true"]{left:0;right:0;margin:0 auto;}
.banner[data-transparent-header="true"] #logo .logo-trans{display:inline}
.banner[data-transparent-header="true"] #logo .logo-main{display:none}
.banner[data-transparent-header="true"] .navbar-nav > li > a, .banner[data-transparent-header="true"] .navbar-nav > li.active > a{color:#fff}
.banner[data-transparent-header="true"] .navbar-nav > li > a:hover, .banner[data-transparent-header="true"] .navbar-nav > li.active > a:hover{color:#fff}
.banner[data-transparent-header="true"] .navbar-nav > li > a:hover:after, .banner[data-transparent-header="true"] .navbar-nav > li.active > a:after, .banner[data-transparent-header="true"] .navbar-nav > li.active > a:hover:after, .banner[data-transparent-header="true"] .navbar-nav > li.active > a:focus:after{background-color:#fff}
header.banner{display:none;}
header.banner.banner--clone{display:block;}
@media (max-width:992px){#logo{margin-left:15px}
.navbar .navbar-nav{float:none;text-align:left;margin:15px 0 10px;}
.navbar .navbar-nav > li > a{padding-top:10px;padding-bottom:10px;}
.navbar .navbar-nav > li > a:after,
.navbar .navbar-nav > li > a:hover:after{height:0 !important}
.navbar .navbar-nav .open .dropdown-toggle{background-color:#eee !important}
.navbar .navbar-nav .dropdown-menu{background-color:#f4f4f4}
.navbar .navbar-nav .dropdown-menu li a{line-height:200%}
.navbar .navbar-nav .dropdown-menu li.active a{background-color:transparent !important;color:#428bca !important;}
}
@media (min-width:992px){.navbar .navbar-nav .dropdown-menu{left:-10px;padding:10px 5px;border:0;box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.2);}
.navbar .navbar-nav .dropdown-menu li a{font-size:13px;text-align:left;padding:8px 20px;color:#828282;}
.navbar .navbar-nav .dropdown:hover .dropdown-menu{display:block}
.navbar .navbar-nav li:last-child .dropdown-menu{right:-5px;left:auto;}
.navbar .caret{display:none}
}
nav.navbar-collapse.bs-navbar-collapse.collapse.in{height:376px;}
.banner--clone{width:100%;position:fixed !important;top:0;left:0;background-color:#fff !important;z-index:120;box-shadow:0 0 0;-webkit-transform:translateY(-100%);-ms-transform:translateY(-100%);transform:translateY(-100%);-webkit-transition:transform 0.2s ease-in-out;-moz-transition:transform 0.2s ease-in-out;transition:transform 0.2s ease-in-out;}
.banner--stick{-webkit-box-shadow:0 1px 2px rgba(43, 48, 51, 0.08) !important;-moz-box-shadow:0 1px 2px rgba(43, 48, 51, 0.08) !important;box-shadow:0 1px 2px rgba(43, 48, 51, 0.08) !important;-webkit-transform:translateY(0%);-ms-transform:translateY(0%);transform:translateY(0%);}
.boxed-mode .banner--clone{left:0;right:0;margin:0 auto;}
.banner--clone #logo{margin-top:16px;margin-bottom:16px;}
.banner--clone #logo img{height:30px !important;width:auto;}
.banner--clone #logo .logo-trans{display:none !important;}
.banner--clone #logo .logo-main{display:inline !important;}
.banner--clone .navbar-nav{margin-top:5px !important;}
.banner--clone .navbar-toggle{top:6px !important;}
.banner--clone .navbar-nav > li > a{ color:#000 !important;}
.navbar .navbar-nav > li.active > a{color: #c50e1f !important}
.banner--clone .navbar-nav > li > a:hover:after, .banner--clone .navbar-nav > li.active > a:after, .banner--clone .navbar-nav > li.active > a:hover:after, .banner--clone .navbar-nav > li.active > a:focus:after{bottom:10px;background-color:#c50e1f !important;}
.banner--clone .navbar-nav .dropdown-menu{margin-top:-2px}

hr.divider-dotted{border-top: 1px dotted #a5b3b4;}
hr.divider-big{margin: 40px 0;}
hr{border: 0 none;}
hr {-moz-border-bottom-colors: none;-moz-border-left-colors: none;-moz-border-right-colors: none;-moz-border-top-colors: none;border-color: #eee -moz-use-text-color -moz-use-text-color;border-image: none;border-right: 0 none;border-style: solid none none;border-width: 1px 0 0;margin-bottom: 20px;margin-top: 20px;}
hr{box-sizing: content-box;height: 0;}

/*headhesive*/
.banner{background:#c50e1f;height:auto;width:100%;color:#fff;position:absolute}.banner a{color:#fff}.banner a:hover{color:#7C2B2D}.logo{float:left;margin:0;font-size:20px;line-height:70px}.nav{height:40px;padding:0;margin:15px 0 0;float:right}.nav__item{height:40px;line-height:40px;padding:0;margin-left:10px;display:inline-block}.nav__item--btn{display:inline-block;padding:0 10pxi;font-size:14px;line-height:38px;padding:0 20px;width:auto}.banner--clone{position:fixed;top:0;left:0;background:#c50e1f;-webkit-transform:translateY(-100%);-ms-transform:translateY(-100%);transform:translateY(-100%);-webkit-transition:all 300ms ease-in-out;-moz-transition:all 300ms ease-in-out;transition:all 300ms ease-in-out}.banner--stick{-webkit-transform:translateY(0%);-ms-transform:translateY(0%);transform:translateY(0%)}